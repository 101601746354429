
import { Companies } from "@/api";
import { Company } from "@/api/types";
import Card from "@/components/common/Card.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Bookings from "@/components/companies/Bookings.vue";
import Delegates from "@/components/companies/Delegates.vue";
import Representatives from "@/components/companies/RepresentativesList.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    PageTitle,
    Card,
    Delegates,
    Bookings,
    Representatives
  }
})
export default class CompaniesViewPage extends Vue {
  tab = null;
  company: Company = {};
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Delete",
      icon: "mdi-delete",
      color: "gray",
      action: "delete"
    },
    {
      text: "Edit",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "edit"
    }
  ];
  get headersTitle() {
    return [{ icon: "mdi-domain", header: this.company.name }];
  }
  get profile() {
    return [
      { title: "Lead Company Name", content: this.company.name },
      { title: "Delegates", content: this.company.countEmployees },
      { title: "Foundation date", content: "1972" },
      { title: "ID", content: this.company.id }
    ];
  }
  get physicalAddress() {
    return [{ content: this.company.address }];
  }
  get documents() {
    return [{ content: "Coming soon..." }];
  }
  get companyId() {
    return this.$route.params.id;
  }
  get representative() {
    return [
      { title: "Name", content: this.company.representativeName },
      { title: "Email", content: this.company.representativeEmail },
      { title: "Phone number", content: this.company.representativePhone },
      { title: "ID", content: this.company.id }
    ];
  }

  async created() {
    try {
      this.company = await Companies.info(this.companyId);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onBack() {
    await this.$router.push({ name: "CompaniesList" });
  }
  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Companies.remove(this.companyId);
        await this.onBack();
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }
  async onEdit() {
    await this.$router.push({
      name: "CompaniesEdit",
      params: { id: this.companyId }
    });
  }
}
