
import { Companies, Events } from "@/api";
import { Booking, BookingsWithPagination } from "@/api/types";
import Table from "@/components/common/Table.vue";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { Table }
})
export default class Bookings extends Vue {
  @Prop({ required: true })
  companyId!: number | string;

  filters = {
    course: "",
    date: ""
  };

  bookings: Array<Booking> = [];
  bookingsWithPagination: BookingsWithPagination = {
    meta: {
      perPage: 12
    }
  };
  page = 1;
  menu = false;
  options = {};
  headers = [
    { text: "Course", value: "course.name" },
    { text: "Address", value: "address" },
    { text: "Date", value: "date" },
    { text: "Hours", value: "hours" },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "100px",
      align: "center"
    }
  ];
  get formattedDate() {
    if (this.filters.date.length > 0) {
      return moment(this.filters.date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    await this.loadBookingsData(val.page);
  }

  @Watch("filters", { deep: true })
  async onChangeFilters() {
    await this.loadBookingsData(this.page);
  }
  async onDownloadTrainingRegister(eventId: number) {
    try {
      const fileName = `${eventId}.pdf`;
      const data = await Events.downloadTrainingRegister(
        eventId,
        this.companyId
      );
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  async loadBookingsData(page = 1) {
    try {
      const bookingsWithPagination = await Companies.bookings(
        this.companyId,
        page,
        this.filters
      );
      this.bookings = bookingsWithPagination.data
        ? bookingsWithPagination.data
        : [];
      this.bookingsWithPagination = bookingsWithPagination;
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  onClearDate() {
    this.filters.date = "";
  }
}
