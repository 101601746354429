
import { CompanyRepresentative } from "@/api/types";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class CompanyRepresentativesList extends Vue {
  @Prop({ required: true })
  representatives!: CompanyRepresentative[];

  // @Prop({ required: true })
  // companyType!: number | string;
  // representatives: CompanyRepresentative[] = [];
  // representativesWithPagination: CompanyRepresentativesWithPagination = {
  //   meta: {
  //     perPage: 12
  //   }
  // };
  nameFilter = "";
  options = {};
  page = 1;
  headers = [
    {
      text: "Contact person",
      value: "name"
    },
    {
      text: "Email",
      value: "email"
    },
    {
      text: "Phone number",
      value: "phone"
    }
  ];

  get filteredRepresentatives() {
    if (!this.representatives) return [];
    return this.representatives.filter(representative =>
      representative.name.includes(this.nameFilter)
    );
  }
  // timeout = 0;
  // @Watch("options", { deep: true })
  // async onOptionsChange(val: any) {
  //   await this.loadCompanyRepresentativesData(val.page, "", this.companyType);
  // }

  // @Watch("nameFilter")
  // async onFilterNameChange(val: any) {
  //   window.clearTimeout(this.timeout);
  //   this.timeout = window.setTimeout(async () => {
  //     await this.loadCompanyRepresentativesData(
  //       this.page,
  //       val,
  //       this.companyType
  //     );
  //   }, 500);
  // }

  // @Watch("companyType")
  // async onTypeChange(val: any) {
  //   await this.loadCompanyRepresentativesData(val.page, "", this.companyType);
  // }

  // async loadCompanyRepresentativesData(
  //   page = 1,
  //   name = "",
  //   type: string | number = ""
  // ) {
  //   try {
  //     const response = await Companies.representatives(
  //       this.companyId,
  //       page,
  //       name,
  //       type
  //     );
  //     this.representatives = response.data ? response.data : [];
  //     this.representativesWithPagination = response;
  //   } catch (e) {
  //     const err = e as any;
  //     await this.$error(err.errors);
  //   }
  // }

  async onView(id: number) {
    // TODO: need implement this in future
    // console.log(": ", id);
  }
}
